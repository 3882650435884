import {FancyBlock} from 'ui-components';
import {getRCAInsightsExecutiveSummeryNetworkRequest} from '../../../../../../../../http/insights.network-requests';
import {useMemo} from 'react';
import {ChangeDescription, RCAInsights} from '../../../../../../../insights/rca/objects';
import {RCAMode} from 'ui-components';
import {
  MetricSampleSentences,
  MetricSampleWoWOverview,
} from '../../../../../../../insights/model-sample/objects';
import {MetricSampleWoWOverviewSection} from './components/metric-overview-wow-section/metric-sample-wow-overview.component';
import {GenericLoading} from '../../../../../../components/general/generic-loading/generic-loading.component';
import {useProductData} from '../../../../../../../../core/hooks/use-product-data.hook';
import {ProductEntities} from '../../../../../../../../objects/models/product-data.model';
import * as React from 'react';
import classes from './rca-executive-summery.module.scss';
import classNames from 'classnames';
import {SegmentsInsightsSection} from './components/segments-insights-section/segments-insights-section.component';
import {MetricSamplesSection} from './components/metric-samples-section/metric-samples-section.component';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {composition} from 'front-core';
import {withLoadBefore} from '../../../../../../../../core/hoc/with-load-before.hoc';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  SIGNAL_ID_PATH_PARAM,
} from '../../../../../../../../constants/app-routes';
import {AnalysisResultStatus} from '../../../../../../../../objects/models/analysis-result.model';

export enum ChangeDescriptionAlias {
  DROP = 'drop',
  SPIKE = 'spike',
}

const ChangeDescriptionAliasMap = {
  [ChangeDescription.INCREASE]: ChangeDescriptionAlias.SPIKE,
  [ChangeDescription.DECREASE]: ChangeDescriptionAlias.DROP,
};

interface RCAExecutiveSummary {
  loopsAlgo: RCAInsights;
  compareToDate: RCAInsights;
  metricSampleInsights: MetricSampleSentences;
  metricSampleWowOverview: MetricSampleWoWOverview;
  metricSampleUnsupported: boolean;
}

interface OwnProps {
  mode: RCAMode;
  resultId: number;
  resultStatus: AnalysisResultStatus;
  signalId: number;
  executiveSummary?: RCAExecutiveSummary;
  className?: string;
}

type AllProps = OwnProps;

const SELECTED_RCA_EXECUTIVE_SUMMERY = 'SELECTED_RCA_EXECUTIVE_SUMMERY';

const LoadingComponent: React.FC = () => {
  const {t} = useTranslation();
  return (
    <FancyBlock title={t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.TITLE)} collapsible={true}>
      <div className={classNames(classes.RCAExecutiveSummaryContainer, classes.Loading)}>
        <GenericLoading />
      </div>
    </FancyBlock>
  );
};

export const RCAExecutiveSummeryComponent: React.FC<AllProps> = (props: AllProps) => {
  const {mode, executiveSummary: executiveSummaryFromProps, resultStatus, className} = props;
  const {t} = useTranslation();
  const {productEntities} = useProductData();

  const executiveSummary = useMemo(
    () => ({
      [RCAMode.COMPARE_TO_DATE]: executiveSummaryFromProps.compareToDate,
      [RCAMode.LOOPS_ALGO]: executiveSummaryFromProps.loopsAlgo,
      metricSampleInsights: executiveSummaryFromProps.metricSampleInsights,
      metricSampleWowOverview: executiveSummaryFromProps.metricSampleWowOverview,
      metricSampleUnsupported: executiveSummaryFromProps.metricSampleUnsupported,
    }),
    [executiveSummaryFromProps]
  );
  const changeDescriptionAlias = useMemo(() => {
    if (!executiveSummary.metricSampleWowOverview) {
      return '';
    }
    return ChangeDescriptionAliasMap[executiveSummary.metricSampleWowOverview.wowChangeDescription];
  }, [executiveSummary]);

  const {trendInsight, periodAllTimeHighOrLowInsight, numberOfUsersInsight} = useMemo(
    () => ({
      trendInsight: executiveSummary.metricSampleInsights?.trendInsight || null,
      periodAllTimeHighOrLowInsight:
        executiveSummary.metricSampleInsights?.periodAllTimeHighOrLowInsight || null,
      numberOfUsersInsight: executiveSummary.metricSampleInsights?.numberOfUsersInsight || null,
    }),
    [executiveSummary]
  );

  const renderEmptyState = () => (
    <div className={classes.ExecutiveSummarySection}>
      <div className={classes.VerticalCenter}>
        <span>{t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.NO_DATA_MESSAGE)}</span>
      </div>
    </div>
  );

  const renderExecutiveSummary = () => (
    <>
      <MetricSampleWoWOverviewSection
        {...executiveSummary.metricSampleWowOverview}
        changeDescriptionAlias={changeDescriptionAlias}
      />
      <MetricSamplesSection
        trendInsight={trendInsight}
        periodAllTimeHighOrLowInsight={periodAllTimeHighOrLowInsight}
        numberOfUsersInsight={numberOfUsersInsight}
        productEntities={productEntities as ProductEntities}
      />
      <SegmentsInsightsSection
        potentialDrivers={executiveSummary[mode]?.potentialDrivers}
        interestingSegmentsPerformanceVsPriorWeek={
          executiveSummary[mode].interestingSegmentsPerformanceVsPriorWeek
        }
        changeDescriptionAlias={changeDescriptionAlias}
      />
    </>
  );

  if (resultStatus === AnalysisResultStatus.RUNNING) {
    return <LoadingComponent />;
  }

  return (
    <FancyBlock
      title={t(TransKeys.DOCUMENT_VIEWER.RCA_EXECUTIVE_SUMMARY.TITLE)}
      collapsible={true}
      collapseInitial={executiveSummary.metricSampleUnsupported}
    >
      <div className={classNames(classes.RCAExecutiveSummaryContainer, className)}>
        {executiveSummary.metricSampleUnsupported && renderEmptyState()}
        {!executiveSummary.metricSampleUnsupported && renderExecutiveSummary()}
      </div>
    </FancyBlock>
  );
};

export const RCAExecutiveSummery = composition<AllProps>(
  RCAExecutiveSummeryComponent,
  withLoadBefore(
    {
      executiveSummary: {
        selectedKey: SELECTED_RCA_EXECUTIVE_SUMMERY,
        actionKey: SELECTED_RCA_EXECUTIVE_SUMMERY,
        request: getRCAInsightsExecutiveSummeryNetworkRequest,
        shouldCall: props =>
          props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined &&
          props[SIGNAL_ID_PATH_PARAM] !== undefined,
        mapPayloadFromProps: props => ({
          [ANALYSIS_RESULT_ID_PATH_PARAM]: props[ANALYSIS_RESULT_ID_PATH_PARAM],
          [SIGNAL_ID_PATH_PARAM]: props[SIGNAL_ID_PATH_PARAM],
        }),
      },
    },
    LoadingComponent
  )
);
