import * as React from 'react';
import classes from './rca-insights-panel.module.scss';
import {Button, FancyHeader, ModalLayout, useMountState} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';
import {composition} from 'front-core';
import {withModalInactiveSourceHandler} from '../../../core/hoc/with-modal-inactive-source-handler.hoc';
import {withLoadBefore} from '../../../core/hoc/with-load-before.hoc';
import {getRCAInsightsNetworkRequest} from '../../../http/insights.network-requests';
import {
  ANALYSIS_RESULT_ID_PATH_PARAM,
  SUBSCRIPTION_ID_PATH_PARAM,
} from '../../../constants/app-routes';
import {RCAInsightSectionComponent} from '../components/rca-insight-section/rca-insight-section.component';
import {RCAInsights} from '../rca/objects';
import {useAmplitude} from '../../../core/hooks/amplitude.hook';
import {useEffect} from 'react';
import {AmplitudeEvent} from '../../../constants/amplitude-event';

interface OwnProps {
  onClose?: () => void;
  [ANALYSIS_RESULT_ID_PATH_PARAM]: number;
  [SUBSCRIPTION_ID_PATH_PARAM]: number;
  insights: RCAInsights;
}

type AllProps = OwnProps;

const SELECTED_RCA_INSIGHTS_KEY = 'RCA_INSIGHTS_PANEL/SELECTED_INSIGHTS';

const RCAInsightsPanelComponent: React.FC<AllProps> = (props: AllProps) => {
  const {onClose, insights} = props;
  const {homepageMetric, potentialDrivers, interestingSegmentsPerformanceVsPriorWeek} = insights;
  const isMounted = useMountState();
  const {t} = useTranslation();
  const notify = useAmplitude();

  useEffect(() => {
    if (isMounted && homepageMetric) {
      notify(AmplitudeEvent.RCA_INSIGHT_PANEL_LOADED, {
        metricId: homepageMetric.id,
      });
    }
  }, [isMounted, homepageMetric, notify]);

  return (
    <div className={classes.RCAInsightsPanelContainer}>
      <ModalLayout
        footer={
          onClose && (
            <Button className={classes.Footer} onClick={onClose}>
              {t(TransKeys.GENERAL.ACTIONS.CLOSE)}
            </Button>
          )
        }
      >
        <FancyHeader title={homepageMetric.name} onClose={onClose} />
        <div className={classes.Body}>
          <RCAInsightSectionComponent {...potentialDrivers} sectionKey={potentialDrivers.key} />
          {interestingSegmentsPerformanceVsPriorWeek.key && (
            <RCAInsightSectionComponent
              {...interestingSegmentsPerformanceVsPriorWeek}
              sectionKey={interestingSegmentsPerformanceVsPriorWeek.key}
            />
          )}
        </div>
      </ModalLayout>
    </div>
  );
};

export const RCAInsightsPanel = composition<AllProps>(
  RCAInsightsPanelComponent,
  withModalInactiveSourceHandler,
  withLoadBefore({
    insights: {
      selectedKey: SELECTED_RCA_INSIGHTS_KEY,
      actionKey: SELECTED_RCA_INSIGHTS_KEY,
      request: getRCAInsightsNetworkRequest,
      shouldCall: props => props[ANALYSIS_RESULT_ID_PATH_PARAM] !== undefined,
      mapPayloadFromProps: props => {
        let payload = {
          [ANALYSIS_RESULT_ID_PATH_PARAM]: props[ANALYSIS_RESULT_ID_PATH_PARAM],
        };
        if (props[SUBSCRIPTION_ID_PATH_PARAM]) {
          payload[SUBSCRIPTION_ID_PATH_PARAM] = props[SUBSCRIPTION_ID_PATH_PARAM];
        }
        return payload;
      },
    },
  })
);
