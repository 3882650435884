import {action, OnSuccessActionHook} from 'front-core';
import {HomepageSettings} from '../../objects/models/homepage.model';
import {ReorderHomepageDTO, UpdateHomepageModelDTO} from '../../objects/dto/homepage.dto';

export enum HomepageActionType {
  ADD_MODEL_TO_HOMEPAGE = '@@homepage/ADD_MODEL_TO_HOMEPAGE',
  REMOVE_MODEL_FROM_HOMEPAGE = '@@homepage/REMOVE_MODEL_FROM_HOMEPAGE',
  UPDATE_HOMEPAGE_SUBSCRIPTION = '@@homepage/UPDATE_HOMEPAGE_SUBSCRIPTION',
  REORDER_HOMEPAGE = '@@homepage/REORDER_HOMEPAGE',
}

export const addModelToHomepage = (data: UpdateHomepageModelDTO, onSuccess?: OnSuccessActionHook) =>
  action(HomepageActionType.ADD_MODEL_TO_HOMEPAGE, data, {onSuccess});

export const removeModelFromHomepage = (
  data: UpdateHomepageModelDTO,
  onSuccess?: OnSuccessActionHook
) => action(HomepageActionType.REMOVE_MODEL_FROM_HOMEPAGE, data, {onSuccess});

export const updateHomepageSubscription = (
  data: Partial<HomepageSettings>,
  onSuccess?: OnSuccessActionHook
) => action(HomepageActionType.UPDATE_HOMEPAGE_SUBSCRIPTION, data, {onSuccess});

export const reorderHomepage = (data: ReorderHomepageDTO, onSuccess?: OnSuccessActionHook) =>
  action(HomepageActionType.REORDER_HOMEPAGE, data, {onSuccess});
