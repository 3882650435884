import yup from '../../config/yup.config';
import {Funnel} from '../models/funnel.model';
import {queryElementValidatorFactory} from './query-builder.dto';
import {values} from 'lodash';
import {TableEntity} from '../models/table.model';
import httpClientService from '../../services/http-client.service';
import {validateNameNetworkRequest} from '../../http/validations.network-requests';
import {exists} from 'front-core';

export type FunnelDTO = Partial<Funnel>;

export const funnelDTOValidator = yup.lazy(obj =>
  yup
    .object()
    .shape({
      id: yup.number().optional().nullable(),
      name: yup
        .string()
        .required()
        .test('unique_name', 'Name already exists', async function (name: string) {
          const {id} = this.parent;
          try {
            await httpClientService.exec(
              validateNameNetworkRequest({
                name,
                existingId: id,
                modelType: 'funnel',
              })
            );
            return true;
          } catch (e) {
            return false;
          }
        }),
      defaultBoundingDays: yup.number().required(),
      entity: yup.string().oneOf(values(TableEntity)).required(),
      steps: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.number().nullable(),
            name: yup.string().nullable(),
            definition: queryElementValidatorFactory(true),
          })
        )
        .min(2, 'At least 2 steps are required')
        .test('unique_step_name', 'Not all step names are unique', function (steps: any[]) {
          const stepNames = steps.map(step => step.name).filter(name => exists(name));
          return new Set(stepNames).size === stepNames.length;
        }),
      shortDescription: yup.string().nullable(),
    })
    .noUnknown()
);
