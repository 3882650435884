import * as React from 'react';
import {AnalysisResult} from '../../../../../objects/models/analysis-result.model';
import {
  GlobalDocumentData,
  GlobalDocumentDataContextProvider,
  useRemoteSourceStated,
} from 'ui-components';
import {useEffect, useMemo} from 'react';
import {getSegmentCategoriesMapNetworkRequest} from '../../../../../http/segment-categories.network-requests';
import {getFavoritesSegmentsNetworkRequest} from '../../../../../http/segments.network-requests';
import {ModelKey} from '../../../../../constants/model-key';
import {
  registerActionListener,
  removeActionListener,
} from '../../../../../store/actions-listener/actions-listener.actions';
import {CoreActionsType} from '../../../../../store/core/core.actions';
import {useDispatch} from 'react-redux';
import {useProductData} from '../../../../../core/hooks/use-product-data.hook';

interface OwnProps {
  analysisResult: AnalysisResult;
  children: any;
}

type AllProps = OwnProps;

export const AppGlobalDocumentDataProvider: React.FC<AllProps> = (props: AllProps) => {
  const {analysisResult, children} = props;
  const dispatch = useDispatch();
  const {actualTeams: teams, defaultTeamId} = useProductData();
  const {source: segmentCategoriesMap, exec: getSegmentCategoriesMap} = useRemoteSourceStated({
    networkRequest: getSegmentCategoriesMapNetworkRequest,
  });
  const {source: favoriteSegments, exec: getFavoriteSegments} = useRemoteSourceStated({
    networkRequest: getFavoritesSegmentsNetworkRequest,
  });

  useEffect(() => {
    getSegmentCategoriesMap();
    getFavoriteSegments();
  }, [getSegmentCategoriesMap, getFavoriteSegments]);

  useEffect(() => {
    const listener = action => {
      if (action.payload.modelKey === ModelKey.FAVORITE_SEGMENTS) {
        getFavoriteSegments();
      }
    };
    dispatch(registerActionListener(CoreActionsType.MODEL_UPDATED, listener));
    return () => {
      dispatch(removeActionListener(CoreActionsType.MODEL_UPDATED, listener));
    };
  }, [dispatch, getFavoriteSegments]);

  const globalDocumentData: GlobalDocumentData = useMemo(
    () => ({
      analysisResultId: analysisResult.id,
      analysisId: analysisResult.analysisId,
      analysisTypeId: analysisResult.analysisTypeId,
      analysisTypeName: analysisResult.analysisTypeName,
      analysisName: analysisResult.userAnalysisName || analysisResult.analysisName,
      analysisRunParameters: analysisResult.runParameters,
      analysisParameters: analysisResult.parameters,
      analysisResultStatus: analysisResult.status,
      segmentCategoriesMap,
      favoriteSegments,
      teams,
      defaultTeamId,
    }),
    [analysisResult, segmentCategoriesMap, favoriteSegments, teams, defaultTeamId]
  );

  return (
    <GlobalDocumentDataContextProvider {...globalDocumentData}>
      {children}
    </GlobalDocumentDataContextProvider>
  );
};
