import * as React from 'react';
import {useMemo} from 'react';
import classNames from 'classnames';
import classes from './kpi-box.module.scss';
import {exists, number2k} from 'front-core';
import {MetricValueType} from '../../../../../../objects/models/metric.model';
import {
  ActionsDropdown,
  ArrowUpRightFromSquareRegularIcon,
  HtmlTooltip,
  MoreIcon,
  TooltipIfOverflow,
  TrashIcon,
  TrendChip,
} from 'ui-components';
import {
  ModelSample,
  ModelSeriesGranularity,
} from '../../../../../../objects/models/model-sample-series.model';
import {HomepageMetricViewer} from '../../../../../homepage/components/homepage-summary/components/homepage-model-samples-viewer/homepage-metric-viewer/homepage-metric-viewer.component';
import {useProductData} from '../../../../../../core/hooks/use-product-data.hook';
import {AnomalyMode} from '../../../../../../objects/models/homepage.model';
import {getSampleWoWChange} from '../../../../../homepage/components/homepage-summary/homepage-summary.utils';

export interface InputKPIProps {
  id: number;
  name: string;
  signalId: number;
  valueType: MetricValueType;
  granularity: ModelSeriesGranularity;
  anomalyMode: AnomalyMode;
  anomalyThreshold: number;
  samples: ModelSample[];
}

interface OwnProps extends InputKPIProps {
  className?: string;
  onRemove?: () => void;
  onClick?: () => void;
}

const formatValue = function (value: number, isPercentageValue: boolean) {
  if (!exists(value)) {
    return '-';
  }
  if (isPercentageValue) {
    return number2k(value * 100) + '%';
  }
  return number2k(value);
};

type AllProps = OwnProps;

export const KPIBox: React.FC<AllProps> = (props: AllProps) => {
  const {id, name, samples, valueType, granularity, onRemove, onClick, className} = props;
  const {userHomepageId} = useProductData();
  const isPercentageValue = valueType === MetricValueType.PERCENTAGE;
  const nonPartialSamples = useMemo(() => samples.filter(s => !s.isPartial), [samples]);
  const lastSample = useMemo(() => {
    if (nonPartialSamples.length === 0) {
      return;
    }
    return nonPartialSamples[nonPartialSamples.length - 1];
  }, [nonPartialSamples]);
  const previousSample = useMemo(() => {
    if (nonPartialSamples.length < 2) {
      return;
    }
    if (granularity === ModelSeriesGranularity.DAY) {
      return nonPartialSamples[nonPartialSamples.length - 8];
    }
    return nonPartialSamples[nonPartialSamples.length - 2];
  }, [nonPartialSamples, granularity]);
  const trend = useMemo(
    () => getSampleWoWChange(lastSample, previousSample),
    [lastSample, previousSample]
  );

  return (
    <HtmlTooltip
      placement={'top'}
      interactive
      title={
        <div className={classes.KPIPopover}>
          <div className={classes.Header}>
            <div className={classes.Title}>{name}</div>
            <div className={classes.Stats}>
              <div className={classes.Item}>
                <span className={classes.Label}>Last {granularity}</span>
                <span className={classes.Value}>
                  {lastSample?.value ? formatValue(lastSample.value, isPercentageValue) : '-'}
                </span>
              </div>
              <div className={classes.Item}>
                <span className={classes.Label}>
                  Previous{granularity === 'day' ? ' week  ' : ' '}
                  {granularity}
                </span>
                <span className={classes.Value}>
                  {previousSample?.value
                    ? formatValue(previousSample.value, isPercentageValue)
                    : '-'}
                </span>
              </div>
              {trend && (
                <div className={classes.Item}>
                  <span className={classes.Label}>Change</span>
                  <span className={classes.Value}>
                    <TrendChip
                      value={trend.value}
                      size={'xsmall'}
                      isSignificant={trend.isSignificant}
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={classes.MetricViewerWrapper}>
            <HomepageMetricViewer
              className={classes.MetricViewer}
              metricId={id}
              homepageId={userHomepageId}
              onAnnotationsClicked={console.log}
              onCreateEditGoal={console.log}
              configuration={{
                anomalyMode: AnomalyMode.LOOPS_ALGO,
                anomalyThreshold: 0.1,
              }}
              showHeader={false}
              showOptions={false}
              showLegend={false}
            />
          </div>
        </div>
      }
    >
      <div className={classNames(classes.KPIBox, className)}>
        <ActionsDropdown
          buttonComponent={({onClick, isOpen}) => (
            <MoreIcon
              onClick={onClick}
              className={classNames(classes.Options, isOpen && classes.Open)}
            />
          )}
          iconDropdown
          actions={[{title: 'Remove', onClick: onRemove, icon: TrashIcon}]}
          dropdownClassName={classes.ActionsDropdown}
        />
        <div className={classes.Info}>
          <div className={classes.Value}>
            {lastSample?.value ? formatValue(lastSample.value, isPercentageValue) : '-'}
          </div>
          {trend && (
            <TrendChip
              value={trend.value}
              isSignificant={trend.isSignificant}
              size={'small'}
              className={classes.Trend}
            />
          )}
        </div>
        <div className={classes.KPIName} onClick={onClick}>
          <TooltipIfOverflow title={name}>
            <span className={classes.Name}>{name}</span>
          </TooltipIfOverflow>
          <ArrowUpRightFromSquareRegularIcon className={classes.Icon} />
        </div>
      </div>
    </HtmlTooltip>
  );
};

KPIBox.defaultProps = {
  samples: [],
};
