import * as React from 'react';
import {useMemo} from 'react';
import {
  ComputedModelSampleSeriesType,
  ModelSampleSeries,
  ModelSampleSeriesType,
  ModelSeriesGranularity,
} from '../../../../../../../../objects/models/model-sample-series.model';
import {
  HomepageAnnotation,
  HomepageFunnel,
} from '../../../../../../../../objects/models/homepage.model';
import {HomepageMetricChart} from '../../homepage-metric-viewer/homepage-metric-chart/homepage-metric-chart.component';
import {ChartConfig} from '../../../../homepage-summary.types';
import {ConfidenceIntervalConfig} from '../../../../../../../../objects/models/user-settings.model';
import {invert, keyBy, take, takeRight, zip} from 'lodash';
import {FunnelChartMode} from 'ui-components';
import {useTranslation} from 'react-i18next';
import TransKeys from 'translations';

interface OwnProps {
  funnel: HomepageFunnel;
  reviewedSignalId?: number;
  granularity: ModelSeriesGranularity;
  chartConfig: ChartConfig;
  annotations: HomepageAnnotation[];
  onAnnotationsClicked?: (annotations: HomepageAnnotation[]) => void;
  configuration: ConfidenceIntervalConfig;
  funnelChartMode: FunnelChartMode;
  className?: string;
}

type AllProps = OwnProps;

const generateIdForSeries = (s: ModelSampleSeries) => `${s.signalId}_${s.seriesType}`;
const FUNNEL_CHART_MODE_TO_SERIES_TYPE = {
  [FunnelChartMode.TOTALS]: ModelSampleSeriesType.LINEAR_FUNNEL_STEP_COUNT_OVER_TIME,
  [FunnelChartMode.CONVERSION_FROM_INITIAL_STEP]:
    ComputedModelSampleSeriesType.FUNNEL_CONVERSION_RATE_FROM_INITIAL_STEP,
  [FunnelChartMode.CONVERSION_FROM_PREVIOUS_STEP]:
    ComputedModelSampleSeriesType.FUNNEL_CONVERSION_RATE_FROM_PREV_STEP,
};

export const HomepageFunnelOvertimeChart: React.FC<AllProps> = (props: AllProps) => {
  const {
    funnel,
    reviewedSignalId,
    granularity,
    chartConfig,
    configuration,
    annotations,
    funnelChartMode,
    onAnnotationsClicked,
    className,
  } = props;
  const {t} = useTranslation();

  const funnelStepsMap = useMemo(() => keyBy(funnel.steps, 'id'), [funnel]);
  const funnelConversionSteps = useMemo(() => {
    const stepSignalIds = funnel.steps.map(s => s.id);
    const zipped = zip(
      take(stepSignalIds, stepSignalIds.length - 1),
      takeRight(stepSignalIds, stepSignalIds.length - 1)
    );
    let map = {};
    zipped.forEach(([from, to]) => {
      map[from] = to;
    });
    map = invert(map);
    return map;
  }, [funnel]);
  const isPercentage = useMemo(() => funnelChartMode !== FunnelChartMode.TOTALS, [funnelChartMode]);
  const chartData = useMemo(() => {
    const entry = (
      funnelChartMode === FunnelChartMode.TOTALS ? funnel.series : funnel.computedSeries
    ).filter(s => {
      const isSameGranularity = s.granularity === granularity;
      const isRelevantType = s.seriesType === FUNNEL_CHART_MODE_TO_SERIES_TYPE[funnelChartMode];
      const isFunnelCompilationRate =
        s.seriesType === ComputedModelSampleSeriesType.FUNNEL_COMPILATION_RATE;
      return isSameGranularity && (isRelevantType || isFunnelCompilationRate);
    });
    const reviewedSeries =
      entry.find(
        s =>
          s.signalId === reviewedSignalId &&
          s.seriesType === FUNNEL_CHART_MODE_TO_SERIES_TYPE[funnelChartMode]
      ) || entry[0];
    const reviewedSeriesId = generateIdForSeries(reviewedSeries);
    let series = [];
    if (funnelChartMode === FunnelChartMode.TOTALS) {
      series = entry.map(s => ({
        ...s,
        name: funnelStepsMap[s.signalId].name,
        id: generateIdForSeries(s),
      }));
    } else {
      series = entry.map(s => ({
        ...s,
        name:
          s.seriesType === ComputedModelSampleSeriesType.FUNNEL_COMPILATION_RATE
            ? t(TransKeys.HOMEPAGE.CHART.FUNNEL_COMPLETION_RATE_NAME)
            : `${funnelStepsMap[funnelConversionSteps[s.signalId]].name} → ${
                funnelStepsMap[s.signalId].name
              }`,
        id: generateIdForSeries(s),
      }));
    }
    return {
      series,
      reviewedSeriesId,
    };
  }, [
    funnel,
    granularity,
    funnelStepsMap,
    funnelConversionSteps,
    funnelChartMode,
    reviewedSignalId,
    t,
  ]);

  return (
    <HomepageMetricChart
      series={chartData.series}
      reviewedSeriesId={chartData.reviewedSeriesId}
      chartConfig={chartConfig}
      confidenceIntervalConfig={configuration}
      isPercentage={isPercentage}
      entity={funnel.entity}
      xLabel={granularity}
      hasCountEntities={false}
      annotations={annotations}
      onAnnotationsClicked={onAnnotationsClicked}
      // onSampleClicked={onRCAFollowUp}
      showLegend={true}
      className={className}
    />
  );
};
