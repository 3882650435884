import * as React from 'react';
import {useEffect, useMemo, useRef} from 'react';
import classNames from 'classnames';
import classes from './homepage-metric-viewer.module.scss';
import {HomepageAnnotation} from '../../../../../../../objects/models/homepage.model';
import {MetricValueType} from '../../../../../../../objects/models/metric.model';
import TransKeys from 'translations';
import {useTranslation} from 'react-i18next';
import {CircularProgress} from '@material-ui/core';
import {useRemoteSourceStated} from 'ui-components';
import {HomepageMetricChart} from './homepage-metric-chart/homepage-metric-chart.component';
import {getHomepageMetricDataNetworkRequest} from '../../../../../../../http/homepage.network-requests';
import {GenericLoading} from '../../../../../../shared/components/general/generic-loading/generic-loading.component';
import {HomepageModelViewerHeader} from '../components/homepage-model-viewer-header/homepage-model-viewer-header.component';
import {
  useChartXLabelForMetric,
  useHomepageChartConfig,
  useHomepageModelData,
  useModelGranularity,
  useModelSampleTimeframe,
  useRequestRCAForSample,
} from '../../../hooks/homepage-summary.hooks';
import {BaseModelSamplesViewerProps} from '../homepage-model-samples-viewer.types';
import {HomepageTimeframeOptions} from '../components/homepage-timeframe-options/homepage-timeframe-options.component';
import {HomepageGranularityOptions} from '../components/homepage-granularity-options/homepage-granularity-options.component';
import {HomepageChartOptions} from '../components/homepage-chart-options/homepage-chart-options.component';

interface OwnProps extends BaseModelSamplesViewerProps {
  metricId: number;
  onAnnotationsClicked?: (annotations: HomepageAnnotation[]) => void;
  onCreateEditGoal?: (goalId?: number) => void;
  onViewMetric?: () => void;
  onViewMetricOpportunities?: () => void;
  onViewSegmentation?: () => void;
}

type AllProps = OwnProps;

export const HomepageMetricViewer: React.FC<AllProps> = (props: AllProps) => {
  const {
    homepageId,
    metricId,
    onAnnotationsClicked,
    onCreateEditGoal,
    onViewMetric,
    onViewMetricOpportunities,
    onViewSegmentation,
    configuration,
    showHeader,
    showOptions,
    showLegend,
    className,
    isLoading: isLoadingFromProps,
  } = props;
  const {t} = useTranslation();
  const homepageRef = useRef<number>(homepageId);
  homepageRef.current = homepageId;
  const {
    source: data,
    exec: getMetric,
    isLoading,
  } = useRemoteSourceStated({
    networkRequest: getHomepageMetricDataNetworkRequest,
  });
  const {metric, annotations = []} = data || {};
  const metricInstanceId = metric?.id;
  // Hooks
  const {granularity, setGranularity, granularityOptions} = useModelGranularity(metric);
  const {reviewedSeries, minSampleDate, maxSampleDate, isNewModel} = useHomepageModelData(
    metric,
    granularity
  );
  const {
    filters,
    isCustomRange,
    showAllPartialPoints,
    onTimeframeOptionSelected,
    onCustomDatesSelected,
    onChangeShowPartialPoints,
    isTimeframeSelected,
  } = useModelSampleTimeframe();
  const {requestRCAForSample: onRCAFollowUp, isLoading: isLoadingRCA} = useRequestRCAForSample(
    metric,
    configuration,
    reviewedSeries?.granularity
  );
  const {chartConfig, setChartConfig} = useHomepageChartConfig();
  const xLabel = useChartXLabelForMetric(metric, reviewedSeries?.granularity);

  const extraChartActions = useMemo(
    () => [
      {
        title: t(TransKeys.HOMEPAGE.ACTIONS.DEFINE_QUARTERLY_TARGET),
        onClick: () => onCreateEditGoal(),
        hide: Boolean(metric?.goal) || !onCreateEditGoal,
      },
      {
        title: showAllPartialPoints
          ? t(TransKeys.HOMEPAGE.CHART.ACTIONS.SHOW_FIRST_PARTIAL_POINT)
          : t(TransKeys.HOMEPAGE.CHART.ACTIONS.SHOW_ALL_PARTIAL_POINTS),
        onClick: () => onChangeShowPartialPoints(!showAllPartialPoints),
        hide: isCustomRange,
      },
    ],
    [t, onCreateEditGoal, showAllPartialPoints, onChangeShowPartialPoints, isCustomRange, metric]
  );
  const chartSeries = useMemo(() => [reviewedSeries], [reviewedSeries]);

  useEffect(() => {
    metricId &&
      getMetric({
        metricId,
        filters,
        homepageId: homepageRef.current,
      });
  }, [
    getMetric,
    metricId,
    homepageRef,
    configuration?.anomalyMode,
    configuration?.anomalyThreshold,
    filters,
  ]);

  const noSeries = !reviewedSeries || reviewedSeries.samples.length === 0;
  const hasNoData = isNewModel || noSeries;
  if (isNewModel) {
    return (
      <div className={classNames(classes.EmptyState, className)}>
        <CircularProgress size={20} className={classes.Progress} />
        {t(TransKeys.HOMEPAGE.SAMPLING_IN_PROGRESS_EMPTY_STATE)}
      </div>
    );
  }
  if (!metric && isLoading) {
    return <GenericLoading />;
  }
  if (noSeries) {
    return (
      <div className={classNames(classes.EmptyState, className)}>
        {t(TransKeys.HOMEPAGE.METRIC_NO_SAMPLES_EMPTY_STATE)}
      </div>
    );
  }

  return (
    <div className={classNames(classes.HomepageMetricViewer, className)}>
      {(isLoading || isLoadingRCA) && !isLoadingFromProps && <GenericLoading />}
      {metric && !hasNoData && showHeader && (
        <HomepageModelViewerHeader
          model={metric}
          onView={onViewMetric}
          onViewOpportunities={onViewMetricOpportunities}
          onViewSegmentation={onViewSegmentation}
          configuration={configuration}
        />
      )}
      {showOptions && (
        <div className={classes.Options}>
          <HomepageGranularityOptions
            granularity={granularity}
            onChange={setGranularity}
            options={granularityOptions}
            disabled={isLoading}
          />
          <HomepageTimeframeOptions
            filters={filters}
            isCustomRange={isCustomRange}
            onTimeframeOptionSelected={onTimeframeOptionSelected}
            onCustomDatesSelected={onCustomDatesSelected}
            isTimeframeSelected={isTimeframeSelected}
            minSampleDate={minSampleDate}
            maxSampleDate={maxSampleDate}
            disabled={isLoading}
          />
          <HomepageChartOptions
            chartConfig={chartConfig}
            onChange={setChartConfig}
            extraActions={extraChartActions}
            hasTarget={Boolean(metric.goal)}
          />
        </div>
      )}
      <div className={classes.Chart}>
        <HomepageMetricChart
          key={metricInstanceId}
          metricName={metric.name}
          series={chartSeries as any}
          chartConfig={chartConfig}
          confidenceIntervalConfig={configuration}
          isPercentage={metric.valueType === MetricValueType.PERCENTAGE}
          entity={metric.entity}
          goal={metric.goal?.value}
          xLabel={xLabel}
          hasCountEntities={reviewedSeries.hasDenominator}
          annotations={annotations}
          onAnnotationsClicked={onAnnotationsClicked}
          onSampleClicked={onRCAFollowUp}
          showLegend={showLegend}
        />
      </div>
    </div>
  );
};

HomepageMetricViewer.defaultProps = {
  showHeader: true,
  showOptions: true,
};
