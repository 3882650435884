import {NRC} from './index';
import {RequestType, toCamelCase, toSnakeCase} from 'front-core';
import {toFiltersDTO} from '../objects/dto/to-filters.dto';
import {HomepageSettings} from '../objects/models/homepage.model';
import {
  GetHomepageFunnelDTO,
  GetHomepageMetricDTO,
  ReorderHomepageDTO,
  UpdateHomepageModelDTO,
} from '../objects/dto/homepage.dto';

export const getHomepageDataNetworkRequest: NRC<void, any> = (homepageId: number, query) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/${homepageId}`,
  query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res =>
    toCamelCase(res.data, ['metrics.*.signal_definition', 'metrics.*.signal_population_filter']),
});

export const getHomepageMetricDataNetworkRequest: NRC<void, any> = (
  data: GetHomepageMetricDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/${data.homepageId}/metric/${data.metricId}`,
  query: data.filters,
  queryTransformer: toFiltersDTO,
  responseTransformer: res =>
    toCamelCase(res.data, ['signal_definition', 'signal_population_filter']),
});

export const getHomepageFunnelDataNetworkRequest: NRC<void, any> = (
  data: GetHomepageFunnelDTO
) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/${data.homepageId}/funnel/${data.funnelId}`,
  query: data.filters,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const getRCACompareDataNetworkRequest: NRC<void, any> = query => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/rca-compare`,
  query,
  queryTransformer: toFiltersDTO,
  responseTransformer: res => toCamelCase(res.data),
});

export const addModelToHomepageNetworkRequest: NRC<UpdateHomepageModelDTO, void> = (
  data: UpdateHomepageModelDTO
) => ({
  method: RequestType.POST,
  relativeUrl: `app/homepage/${data.homepageId}/item`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const removeModelFromHomepageNetworkRequest: NRC<UpdateHomepageModelDTO, void> = (
  data: UpdateHomepageModelDTO
) => ({
  method: RequestType.DELETE,
  relativeUrl: `app/homepage/${data.homepageId}/item`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const getHomepageSettingsNetworkRequest: NRC<void, void> = (homepageId: number) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/${homepageId}/settings`,
  responseTransformer: res => toCamelCase(res.data),
});

export const getHomepageSubscriptionNetworkRequest: NRC<void, void> = (homepageId: number) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/${homepageId}/subscription`,
  responseTransformer: res => toCamelCase(res.data),
});

export const updateHomepageSubscriptionNetworkRequest: NRC<Partial<HomepageSettings>, void> = (
  data: Partial<HomepageSettings>
) => ({
  method: RequestType.PUT,
  relativeUrl: `app/homepage/${data.homepageId}/subscription`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const reorderHomepageNetworkRequest: NRC<Partial<ReorderHomepageDTO>, void> = (
  data: ReorderHomepageDTO
) => ({
  method: RequestType.PUT,
  relativeUrl: `app/homepage/${data.homepageId}/reorder`,
  body: data,
  bodyTransformer: toSnakeCase,
  responseTransformer: res => toCamelCase(res.data),
});

export const sendHomepageSubscriptionTestNetworkRequest: NRC<void, any> = (homepageId: number) => ({
  method: RequestType.GET,
  relativeUrl: `app/homepage/${homepageId}/subscription/test`,
  responseTransformer: res => toCamelCase(res.data),
});
