import * as React from 'react';
import classNames from 'classnames';
import {
  HomepageModelSamplesViewerFilters,
  TimeframeOption,
} from '../../homepage-model-samples-viewer.types';
import {
  DateRange,
  DateRangeDialog,
  PopoverWrapper,
  CalendarIcon,
  ChevronDownIcon,
} from 'ui-components';
import {useCallback, useMemo, useRef} from 'react';
import {TIMEFRAME_OPTIONS} from '../../homepage-model-samples-viewer.consts';
import moment from 'moment';
import {TIME_FORMATS} from '../../../../../../../../constants/time-formats';
import classes from './homepage-timeframe-options.module.scss';

interface OwnProps {
  filters: HomepageModelSamplesViewerFilters;
  isCustomRange: boolean;
  onTimeframeOptionSelected: (value: TimeframeOption) => void;
  onCustomDatesSelected: (value: DateRange) => void;
  isTimeframeSelected: (option: TimeframeOption) => boolean;
  minSampleDate: Date;
  maxSampleDate: Date;
  disabled?: boolean;
  className?: string;
}

type AllProps = OwnProps;

export const HomepageTimeframeOptions: React.FC<AllProps> = (props: AllProps) => {
  const {
    filters,
    isCustomRange,
    onTimeframeOptionSelected,
    onCustomDatesSelected: onCustomDatesSelectedFromProps,
    isTimeframeSelected,
    minSampleDate,
    maxSampleDate,
    disabled,
    className,
  } = props;
  const customSelectorRef = useRef<{close: () => void}>(null);

  const onCustomDatesSelected = useCallback(
    (value: DateRange) => {
      onCustomDatesSelectedFromProps(value);
      customSelectorRef.current?.close();
    },
    [onCustomDatesSelectedFromProps, customSelectorRef]
  );
  const timeframeOptions = useMemo(
    () =>
      TIMEFRAME_OPTIONS.map(o => {
        return {
          label: `${o.count}${o.unit}`.toUpperCase(),
          isSelected: isTimeframeSelected(o),
          onClick: () => onTimeframeOptionSelected(o),
        };
      }),
    [onTimeframeOptionSelected, isTimeframeSelected]
  );
  const dateRangeText = useMemo(() => {
    const start = moment.utc(filters.fromDate);
    const end = filters.toDate ? moment.utc(filters.toDate) : undefined;
    let str = `${start.format(TIME_FORMATS.READABLE_DATE)}`;
    if (end) {
      str += ` - ${end.format(TIME_FORMATS.READABLE_DATE)}`;
    }
    return str;
  }, [filters]);

  if (!timeframeOptions) {
    return null;
  }

  return (
    <div className={classNames(classes.HomepageTimeframeOptions, className)}>
      {timeframeOptions.map(o => (
        <div
          key={o.label}
          onClick={disabled ? undefined : o.onClick}
          className={classNames(
            classes.Option,
            o.isSelected && classes.Selected,
            disabled && classes.Disabled
          )}
        >
          {o.label}
        </div>
      ))}
      <PopoverWrapper
        ref={customSelectorRef}
        buttonRenderer={({onClick, isOpen}) => (
          <div
            key={'custom'}
            onClick={disabled ? undefined : onClick}
            className={classNames(
              classes.Option,
              isOpen && classes.Selected,
              isCustomRange && classes.Selected,
              disabled && classes.Disabled
            )}
          >
            <div className={classes.Custom}>
              {isCustomRange ? dateRangeText : <CalendarIcon />}
              <ChevronDownIcon className={classes.ChevronIcon} />
            </div>
          </div>
        )}
      >
        <DateRangeDialog
          // @ts-ignore
          value={
            isCustomRange
              ? {
                  from: filters.fromDate,
                  to: filters.toDate,
                }
              : undefined
          }
          showQuickOptions={false}
          maxDate={maxSampleDate}
          minDate={minSampleDate}
          onSubmit={onCustomDatesSelected}
        />
      </PopoverWrapper>
    </div>
  );
};
