import {useContext} from 'react';
import {GlobalDocumentDataContext, RCAMode} from 'ui-components';
import {RCAExecutiveSummery} from './analyses-executive-summaries/rca-executive-summery/rca-executive-summery.component';
import {AnalysisTypeId} from '../../../../../../constants/analysis-type-id';
import {AnalysisResultStatus} from '../../../../../../objects/models/analysis-result.model';

interface OwnProps {
  className?: string;
  rcaMode?: RCAMode;
}

type AllProps = OwnProps;

export const AppExecutiveSummeryViewer: React.FC<AllProps> = (props: AllProps) => {
  const {className} = props;
  const data = useContext(GlobalDocumentDataContext);

  switch (data.analysisTypeId) {
    case AnalysisTypeId.RCA_ANALYSIS:
      return (
        <RCAExecutiveSummery
          mode={props.rcaMode}
          resultId={data.analysisResultId}
          resultStatus={data.analysisResultStatus as AnalysisResultStatus}
          signalId={data.analysisParameters.goal}
          className={className}
        />
      );
    default:
      return null;
  }
};
