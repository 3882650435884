import * as React from 'react';
import classNames from 'classnames';
import classes from './input-kpis.module.scss';
import {PlusRegularIcon, PopoverWrapper, TriangleSolidIcon} from 'ui-components';
import {KPIBox} from '../kpi-box/kpi-box.component';
import {MetricSelector} from '../../../../components/metric-selector/metric-selector.component';
import {useCallback, useMemo, useRef} from 'react';
import {MetricPage, MetricSeries} from '../../../../../../objects/models/metric-page.model';
import {ModelSeriesGranularity} from '../../../../../../objects/models/model-sample-series.model';

interface OwnProps {
  metric: MetricPage;
  onAddKPI: (metricId: number) => void;
  onRemoveKPI: (metricId: number) => void;
  onKPIClicked: (metricId: number) => void;
  className?: string;
}

type AllProps = OwnProps;

const MAX_RENDERED_KPIS = 4;
const DEFAULT_GRANULARITY = ModelSeriesGranularity.WEEK;

const getSeries = (series: MetricSeries[]) => {
  return series.find(s => s.granularity === DEFAULT_GRANULARITY) || series[0];
};

export const InputKPIs: React.FC<AllProps> = (props: AllProps) => {
  const {metric, onAddKPI: onAddKPIFromProps, onRemoveKPI, onKPIClicked, className} = props;
  const selectorRef = useRef<any>(null);

  const {root, kpis} = useMemo(() => {
    if (!metric) {
      return {
        root: undefined,
        kpis: [],
      };
    }
    return {
      root: {
        id: metric.id,
        name: metric.name,
        signalId: metric.signalId,
        valueType: metric.valueType,
        granularity: getSeries(metric.series)?.granularity,
        samples: getSeries(metric.series)?.samples || [],
      },
      kpis: metric.inputMetrics.map(im => ({
        id: im.id,
        name: im.name,
        signalId: im.signalId,
        valueType: im.valueType,
        granularity: getSeries(im.series)?.granularity,
        samples: getSeries(im.series)?.samples || [],
      })),
    };
  }, [metric]);

  const onAddKPI = useCallback(
    (metricId: number) => {
      onAddKPIFromProps(metricId);
      selectorRef.current?.close();
    },
    [onAddKPIFromProps]
  );
  const excludeMetricIds = useMemo(() => [root.id, ...kpis.map(k => k.id)], [root, kpis]);

  return (
    <div className={classNames(classes.InputKPIs, className)}>
      <div className={classes.Header}>
        <div className={classes.Title}>Input KPIs</div>
      </div>
      <div className={classes.Content}>
        <div className={classes.Root}>
          <KPIBox
            {...root}
            anomalyMode={metric.anomalyMode}
            anomalyThreshold={metric.anomalyThreshold}
          />
        </div>
        {kpis.length > 0 && (
          <div
            className={classes.Row}
            style={{
              width: `calc(${Math.min(kpis.length, 3) * 26}rem + ${
                Math.min(kpis.length, 3) * 1.2
              }rem)`,
            }}
          >
            <TriangleSolidIcon className={classes.Arrow} />
          </div>
        )}
        <div className={classes.Nodes}>
          {kpis.map(kpi => (
            <KPIBox
              {...kpi}
              key={kpi.id}
              className={classes.KPINode}
              onRemove={() => onRemoveKPI(kpi.id)}
              onClick={() => onKPIClicked(kpi.id)}
              anomalyMode={metric.anomalyMode}
              anomalyThreshold={metric.anomalyThreshold}
            />
          ))}
          {kpis.length < MAX_RENDERED_KPIS && (
            <PopoverWrapper
              ref={selectorRef}
              buttonRenderer={({isOpen, onClick}) => (
                <div className={classNames(classes.Add, isOpen && classes.Open)} onClick={onClick}>
                  <PlusRegularIcon className={classes.Plus} />
                  <div className={classes.Primary}>Add Input KPI</div>
                  <div className={classes.Secondary}>Which KPIs influence this KPI?</div>
                </div>
              )}
            >
              <MetricSelector
                onChange={metricId => onAddKPI(metricId)}
                excludeIds={excludeMetricIds}
              />
            </PopoverWrapper>
          )}
        </div>
      </div>
    </div>
  );
};
