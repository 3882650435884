import {NotSignificantUsersChange, RCAInsightSentence} from './objects';
import sharedClasses from '../insights.module.scss';
import {
  ChangeInTotalEntitiesSentencePart,
  SegmentNameSentencePart,
} from '../components/shared-sentence-parts/shared-sentence-parts.components';

interface OwnProps extends RCAInsightSentence {
  formattedSentenceParts: NotSignificantUsersChange;
}
type AllProps = OwnProps;

const NotSignificantUsersChangeFormatter: React.FC<AllProps> = (props: AllProps) => {
  const {formattedSentenceParts, sentenceParts} = props;
  const {changeInTotalEntities, entityChangeDescription, entity} = formattedSentenceParts;
  const {segmentGroupName, segmentName} = sentenceParts;

  return (
    <div className={sharedClasses.InsightSentenceContainer}>
      <SegmentNameSentencePart segmentGroupName={segmentGroupName} segmentName={segmentName} />
      <span className={sharedClasses.InsightSentencePart}>{`had`}</span>
      <ChangeInTotalEntitiesSentencePart changeInTotalEntities={changeInTotalEntities} />
      <span
        className={sharedClasses.InsightSentencePart}
      >{`${entityChangeDescription} ${entity} compared to the prior week.`}</span>
    </div>
  );
};

export {NotSignificantUsersChangeFormatter};
